/* ActiveCampaign subscribe styles - See KEO Overrides in main.css */
#_form_5_{
    font-size:14px;
    line-height:1.6;
    font-family:arial, helvetica, sans-serif;
    margin:0
}
#_form_5_ *{
    outline:0
}
._form_hide{
    display:none;
    visibility:hidden
}
._form_show{
    display:block;
    visibility:visible
}
#_form_5_._form-top{
    top:0
}
#_form_5_._form-bottom{
    bottom:0
}
#_form_5_._form-left{
    left:0
}
#_form_5_._form-right{
    right:0
}
#_form_5_ input[type="text"],#_form_5_ input[type="tel"],#_form_5_ input[type="date"],#_form_5_ textarea{
    padding:6px;
    height:auto;
    border:#979797 1px solid;
    border-radius:4px;
    color:#000000 !important;
    font-size:14px;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
#_form_5_ textarea{
    resize:none
}
#_form_5_ ._submit{
    -webkit-appearance:none;
    cursor:pointer;
    font-family:arial, sans-serif;
    font-size:14px;
    text-align:center;
    background:#333333 !important;
    border:0 !important;
    -moz-border-radius:4px !important;
    -webkit-border-radius:4px !important;
    border-radius:4px !important;
    color:#FFFFFF !important;
    padding:10px !important
}
#_form_5_ ._submit:disabled{
    cursor:not-allowed;
    opacity:0.4
}
#_form_5_ ._submit.processing{
    position:relative
}
#_form_5_ ._submit.processing::before{
    content:"";
    width:1em;
    height:1em;
    position:absolute;
    z-index:1;
    top:50%;
    left:50%;
    border:double 3px transparent;
    border-radius:50%;
    background-image:linear-gradient(#333333, #333333), conic-gradient(#333333, #FFFFFF);
    background-origin:border-box;
    background-clip:content-box, border-box;
    animation:1200ms ease 0s infinite normal none running _spin
}
#_form_5_ ._submit.processing::after{
    content:"";
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:#333333 !important;
    border:0 !important;
    -moz-border-radius:4px !important;
    -webkit-border-radius:4px !important;
    border-radius:4px !important;
    color:#FFFFFF !important;
    padding:10px !important
}
@keyframes _spin{
    0%{
        transform:translate(-50%, -50%) rotate(90deg)
    }
    100%{
        transform:translate(-50%, -50%) rotate(450deg)
    }
}
#_form_5_ ._close-icon{
    cursor:pointer;
    background-image:url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1u_forms-close-dark.png");
    background-repeat:no-repeat;
    background-size:14.2px 14.2px;
    position:absolute;
    display:block;
    top:11px;
    right:9px;
    overflow:hidden;
    width:16.2px;
    height:16.2px
}
#_form_5_ ._close-icon:before{
    position:relative
}
#_form_5_ ._form-body{
    margin-bottom:30px
}
#_form_5_ ._form-image-left{
    width:150px;
    float:left
}
#_form_5_ ._form-content-right{
    margin-left:164px
}
#_form_5_ ._form-branding{
    color:#fff;
    font-size:10px;
    clear:both;
    text-align:left;
    margin-top:30px;
    font-weight:100
}
#_form_5_ ._form-branding ._logo{
    display:block;
    width:130px;
    height:14px;
    margin-top:6px;
    background-image:url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
    background-size:130px auto;
    background-repeat:no-repeat
}
#_form_5_ .form-sr-only{
    position:absolute;
    width:1px;
    height:1px;
    padding:0;
    margin:-1px;
    overflow:hidden;
    clip:rect(0, 0, 0, 0);
    border:0
}
#_form_5_ ._form-label,#_form_5_ ._form_element ._form-label{
    font-weight:bold;
    margin-bottom:5px;
    display:block
}
#_form_5_._dark ._form-branding{
    color:#333
}
#_form_5_._dark ._form-branding ._logo{
    background-image:url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png")
}
#_form_5_ ._form_element{
    position:relative;
    margin-bottom:10px;
    font-size:0;
    max-width:100%
}
#_form_5_ ._form_element *{
    font-size:14px
}
#_form_5_ ._form_element._clear{
    clear:both;
    width:100%;
    float:none
}
#_form_5_ ._form_element._clear:after{
    clear:left
}
#_form_5_ ._form_element input[type="text"],#_form_5_ ._form_element input[type="date"],#_form_5_ ._form_element select,#_form_5_ ._form_element textarea:not(.g-recaptcha-response){
    display:block;
    width:100%;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    font-family:inherit
}
#_form_5_ ._field-wrapper{
    position:relative
}
#_form_5_ ._inline-style{
    float:left
}
#_form_5_ ._inline-style input[type="text"]{
    width:150px
}
#_form_5_ ._inline-style:not(._clear)+._inline-style:not(._clear){
    margin-left:20px
}
#_form_5_ ._form_element img._form-image{
    max-width:100%
}
#_form_5_ ._form_element ._form-fieldset{
    border:0;
    padding:0.01em 0 0 0;
    margin:0;
    min-width:0
}
#_form_5_ ._clear-element{
    clear:left
}
#_form_5_ ._full_width{
    width:100%
}
#_form_5_ ._form_full_field{
    display:block;
    width:100%;
    margin-bottom:10px
}
#_form_5_ input[type="text"]._has_error,#_form_5_ textarea._has_error{
    border:#F37C7B 1px solid
}
#_form_5_ input[type="checkbox"]._has_error{
    outline:#F37C7B 1px solid
}
#_form_5_ ._error{
    display:block;
    position:absolute;
    font-size:14px;
    z-index:10000001
}
#_form_5_ ._error._above{
    padding-bottom:4px;
    bottom:39px;
    right:0
}
#_form_5_ ._error._below{
    padding-top:8px;
    top:100%;
    right:0
}
#_form_5_ ._error._above ._error-arrow{
    bottom:-4px;
    right:15px;
    border-left:8px solid transparent;
    border-right:8px solid transparent;
    border-top:8px solid #FFDDDD
}
#_form_5_ ._error._below ._error-arrow{
    top:0;
    right:15px;
    border-left:8px solid transparent;
    border-right:8px solid transparent;
    border-bottom:8px solid #FFDDDD
}
#_form_5_ ._error-inner{
    padding:12px 12px 12px 36px;
    background-color:#FFDDDD;
    background-image:url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 3V9H7V3H9ZM9 13V11H7V13H9Z' fill='%23CA0000'/%3E%3C/svg%3E");
    background-repeat:no-repeat;
    background-position:12px center;
    font-size:14px;
    font-family:arial, sans-serif;
    font-weight:600;
    line-height:16px;
    color:#000;
    text-align:center;
    text-decoration:none;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    border-radius:4px;
    box-shadow:0px 1px 4px rgba(31, 33, 41, 0.298295)
}
#_form_5_ ._error-inner._form_error{
    margin-bottom:5px;
    text-align:left
}
#_form_5_ ._button-wrapper ._error-inner._form_error{
    position:static
}
#_form_5_ ._error-inner._no_arrow{
    margin-bottom:10px
}
#_form_5_ ._error-arrow{
    position:absolute;
    width:0;
    height:0
}
#_form_5_ ._error-html{
    margin-bottom:10px
}
.pika-single{
    z-index:10000001 !important
}
#_form_5_ input[type="text"].datetime_date{
    width:69%;
    display:inline
}
#_form_5_ select.datetime_time{
    width:29%;
    display:inline;
    height:32px
}
#_form_5_ input[type="date"].datetime_date{
    width:69%;
    display:inline-flex
}
#_form_5_ input[type="time"].datetime_time{
    width:29%;
    display:inline-flex
}
@media (min-width:320px) and (max-width:667px){
    ::-webkit-scrollbar{
        display:none
    }
    #_form_5_{
        margin:0;
        width:100%;
        min-width:100%;
        max-width:100%;
        box-sizing:border-box
    }
    #_form_5_ *{
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
        font-size:1em
    }
    #_form_5_ ._form-content{
        margin:0;
        width:100%
    }
    #_form_5_ ._form-inner{
        display:block;
        min-width:100%
    }
    #_form_5_ ._form-title,#_form_5_ ._inline-style{
        margin-top:0;
        margin-right:0;
        margin-left:0
    }
    #_form_5_ ._form-title{
        font-size:1.2em
    }
    #_form_5_ ._form_element{
        margin:0 0 20px;
        padding:0;
        width:100%
    }
    #_form_5_ ._form-element,#_form_5_ ._inline-style,#_form_5_ input[type="text"],#_form_5_ label,#_form_5_ p,#_form_5_ textarea:not(.g-recaptcha-response){
        float:none;
        display:block;
        width:100%
    }
    #_form_5_ ._row._checkbox-radio label{
        display:inline
    }
    #_form_5_ ._row,#_form_5_ p,#_form_5_ label{
        margin-bottom:0.7em;
        width:100%
    }
    #_form_5_ ._row input[type="checkbox"],#_form_5_ ._row input[type="radio"]{
        margin:0 !important;
        vertical-align:middle !important
    }
    #_form_5_ ._row input[type="checkbox"]+span label{
        display:inline
    }
    #_form_5_ ._row span label{
        margin:0 !important;
        width:initial !important;
        vertical-align:middle !important
    }
    #_form_5_ ._form-image{
        max-width:100%;
        height:auto !important
    }
    #_form_5_ input[type="text"]{
        padding-left:10px;
        padding-right:10px;
        font-size:16px;
        line-height:1.3em;
        -webkit-appearance:none
    }
    #_form_5_ input[type="radio"],#_form_5_ input[type="checkbox"]{
        display:inline-block;
        width:1.3em;
        height:1.3em;
        font-size:1em;
        margin:0 0.3em 0 0;
        vertical-align:baseline
    }
    #_form_5_ button[type="submit"]{
        padding:20px;
        font-size:1.5em
    }
    #_form_5_ ._inline-style{
        margin:20px 0 0 !important
    }
}
#_form_5_{
    position:relative;
    text-align:left;
    margin:25px auto 0;
    padding-top:20px;
    padding-right:20px;
    padding-bottom:20px;
    padding-left:20px;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    *zoom:1;
    background:#FFFFFF !important;
    border:0px solid #B0B0B0 !important;
    max-width:500px;
    -moz-border-radius:0px !important;
    -webkit-border-radius:0px !important;
    border-radius:0px !important;
    color:#000000
}
#_form_5_._inline-form,#_form_5_._inline-form ._form-content{
    font-family:"IBM Plex Sans", Helvetica, sans-serif
}
#_form_5_._inline-form ._row span,#_form_5_._inline-form ._row label{
    font-family:"IBM Plex Sans", Helvetica, sans-serif;
    font-size:14px;
    font-weight:400;
    line-height:1.6em
}
#_form_5__inlineform input[type="text"],#_form_5__inlineform input[type="date"],#_form_5__inlineform input[type="tel"],#_form_5__inlineform select,#_form_5__inlineform textarea:not(.g-recaptcha-response){
    font-family:"IBM Plex Sans", Helvetica, sans-serif;
    font-size:14px;
    font-weight:400;
    font-color:#000000;
    line-height:1.6em
}
#_form_5_._inline-form ._html-code *,#_form_5_._inline-form ._form-thank-you{
    font-family:"IBM Plex Sans", Helvetica, sans-serif
}
#_form_5_._inline-form ._form-label{
    font-family:"IBM Plex Sans", Helvetica, sans-serif;
    font-size:14px;
    font-weight:700;
    line-height:1.6em
}
#_form_5_._inline-form ._submit{
    font-family:"IBM Plex Sans", Helvetica, sans-serif
}
#_form_5_._inline-form ._form-title{
    font-size:22px;
    line-height:normal;
    font-weight:600;
    margin-bottom:0
}
#_form_5_._inline-form ._form-branding{
    font-family:"IBM Plex Sans", Helvetica, sans-serif;
    font-size:13px;
    font-weight:100;
    font-style:normal;
    text-decoration:none
}
#_form_5_:before,#_form_5_:after{
    content:" ";
    display:table
}
#_form_5_:after{
    clear:both
}
#_form_5_._inline-style{
    width:auto;
    display:inline-block
}
#_form_5_._inline-style input[type="text"],#_form_5_._inline-style input[type="date"]{
    padding:10px 12px
}
#_form_5_._inline-style button._inline-style{
    position:relative;
    top:27px
}
#_form_5_._inline-style p{
    margin:0
}
#_form_5_._inline-style ._button-wrapper{
    position:relative;
    margin:27px 12.5px 0 20px
}
#_form_5_ ._form-thank-you{
    position:relative;
    left:0;
    right:0;
    text-align:center;
    font-size:18px
}
@media (min-width:320px) and (max-width:667px){
    #_form_5_._inline-form._inline-style ._inline-style._button-wrapper{
        margin-top:20px !important;
        margin-left:0 !important
    }
}
#_form_5_ .iti.iti--allow-dropdown.iti--separate-dial-code{
    width:100%
}
#_form_5_ .iti input{
    width:100%;
    height:32px;
    border:#979797 1px solid;
    border-radius:4px
}
#_form_5_ .iti--separate-dial-code .iti__selected-flag{
    background-color:#FFFFFF;
    border-radius:4px
}
#_form_5_ .iti--separate-dial-code .iti__selected-flag:hover{
    background-color:rgba(0, 0, 0, 0.05)
}
#_form_5_ .iti__country-list{
    border-radius:4px;
    margin-top:4px;
    min-width:460px
}
#_form_5_ .iti__country-list--dropup{
    margin-bottom:4px
}
#_form_5_ .phone-error-hidden{
    display:none
}
#_form_5_ .phone-error{
    color:#E40E49
}
#_form_5_ .phone-input-error{
    border:1px solid #E40E49 !important
}
