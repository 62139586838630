@import 'shared';
@import 'subscribe-styles.css';
@import 'subscribe-interest-styles.css';
$fc-face-background: #183642 !default;

body {
    font-family: $font;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.2;
    color: $txt;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

 /* text colours */
h1, h2, h3 {
    color: $five; /* navy */
}
h1, h2 {
    font-size: 3.3em;
}
h3 {
    font-size: 2em;
}
h4 {
    color: $four; /* blue */
}
.fancy {
    font-family: $fancy;
    font-style: italic;
    font-weight: 700;
}
.highlight {
/* used for colour cycles - eg product spaces items */
    color: $one; /* pink default */
}
.c1 {
    color: $one; /* pink */
}
/* yellow not used as text colour */
.c3 {
    color: $three; /* khaki */
}
.c4 {
    color: $four; /* blue */
}
.c5 {
    color: $five; /* navy */
}
.yellow{
    color: $two; /* navy */
}
ul,
ol {
    li { /* add spacing between items */
        padding: 10px;
    }
}
ul.logo {
    list-style: none;
    padding: 0px;
    padding-left: 0px; /* for logo icon */
    margin: 0px;
    li {
        position: relative;
        text-align: left;
        padding-left: 40px; /* for logo icon */
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 5px;
            left: 0px;
            background-image: url("../assets/logo-blue.svg");
            background-size: 20px 22px;
            width: 20px;
            height: 22px;
        }
        &:nth-of-type(4n+2):before {
            background-image: url("../assets/logo-khaki.svg");
        }
        &:nth-of-type(4n+3):before {
            background-image: url("../assets/logo-yellow.svg");
        }
        &:nth-of-type(4n):before {
            background-image: url("../assets/logo-pink.svg");
        }
    }
}

/* inline-blocks */
a.button,
.button {
    @include button;
    text-transform: none;
    margin: 5px;
    &:first-of-type {
        margin-left: 0px;
    }
    /* in subscribe block */
    &.one {
        color: #FFF;
        background-color: $one;
        border-color: $one;
        &:before {
            background-color: $one;
        }
        &:hover {
            background-color: transparent;
        }
    }
    /* ToDo: add download style */
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:30px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    max-width: 50%;
    margin-left:30px;
    margin-bottom:10px;
}

/* block styles */
.shadow {
    @include drop_shadow;
    padding: 5%;
    margin-bottom: 20px;
}
.col-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-content: space-between;
    align-content: flex-start; /* top */
    width: 100%;
    box-sizing: border-box;
    margin: 0px -1%;
}
.two-col,
.three-col,
.four-col {
    display: inline-block;
    vertical-align: top;
    padding: 1%;
    box-sizing: border-box;
}
.two-col {
    width: 48%;
}
.three-col {
    width: 30%;
}
.four-col {
    width: 23%;
}
.floorplan{
    display: grid;
    grid-template-columns: 0.4fr 1.6fr;
    gap: 2rem;
    @media only screen and (max-width: 550px){
        grid-template-columns: 1fr;
        img{
            max-width: 100px;
            margin: auto;
        }
        p{
            text-align: center;
        }

    }
}


ol.lettered-bullet{
 
    counter-reset: listStyle;
    gap:10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0;
    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr;
    }
    li{
        // width: 40%;
        counter-increment: listStyle;
        position: relative;
        margin: 0;
        margin-left: 28px;
     
        &::before {
            content: counter(listStyle, upper-alpha);
            display: inline-flex;
            position: absolute;
            top:5px;
            right: 100%;
            background-color: $three;
            border-radius: 50%;
            width: 30px;
            color: white;
            font-weight: 700;
            height: 30px;
            justify-content: center;
            align-items: center;
        }
    }
}
ol{
    &.custom-list-blue, &.custom-list-khaki, &.custom-list-pink{
        list-style: none;
        display: grid;
        grid-template-columns: 40px 1fr;
        gap:1rem;
        padding: 0;
        li:nth-child(odd){
            display: inline-flex;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: 700;
        }
        li:nth-child(even){
            padding:10px 20px;
            border-bottom:1px solid #333;
        }
    }
    &.custom-list-blue li:nth-child(odd){
        background-color: $four;
    }
    &.custom-list-khaki li:nth-child(odd){
        background-color: $three;
    }
    &.custom-list-pink li:nth-child(odd){
        background-color: $one;
    }
}
@media only screen and (max-width: 1100px){
    .four-col {
        width: 48%;
    }
}
@media only screen and (max-width: 960px){
    .three-col {
        width: 48%;
    }
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    /* stack cols */
    .col-wrap {
        justify-content: center;
        margin: 0px;
    }
    .two-col,
    .three-col,
    .four-col {
        width: 100%;
        padding: 0px;
    }
}
@media only screen and (max-width: 550px){
    h1, h2 {
        font-size: 2em;
    }
    h3 {
        font-size: 1.5em;
    }
}
@media only screen and (max-width: 300px){
    .button {
        padding: 10px;
    }
}

.img-rounded{
    border-radius: 50%;
    overflow: hidden;
}

.savings-label {
    display: inline-block;
    color: #FFF;
    font-weight: bold;
    background-color: $four;
    padding: 5px 10px;
    margin: 5px 0px;
    font-style: italic;
}
